import axios from "axios";
import { handleApiError } from "./api";

export const getSearchResult = async (ct: any, rs: any) => {
	try {
		const apiUrl = `${process.env.REACT_APP_API_URL}GetCompanion`;
		const filterPayload = {
			cityID: ct,
			residentID: rs !== "none" ? rs : 0,
			serviceContent: "",
			preferenceContents: "",
			loveOnePriority: "",
			availabilityDay: "",
			language: 0,
		};
		const response = await axios.post(apiUrl, filterPayload);
		return response.data;
	} catch (error) {
		handleApiError(error);
		throw error;
	}
};

export const getSearchResultByLanguage = async (languageID: string) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_DEFAULT_URL}api/companion/AllCompanionsByLanguage/${languageID}`
		);
		return response.data;
	} catch (error) {
		throw new Error("Failed to fetch companions by language");
	}
};

export const getSearchResultByFacility = async (facilityID: string) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_DEFAULT_URL}api/companion/AllCompanionsByFacility/${facilityID}`
		);
		return response.data;
	} catch (error) {
		throw new Error("Failed to fetch search results by facility");
	}
};

export const getSearchResultByLanguageAndFacility = async (languageID: string, facilityID: string) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_DEFAULT_URL}api/companion/AllCompanionsByLanguageandFacility/${languageID}/${facilityID}`
		);
		return response.data;
	} catch (error) {
		throw new Error("Failed to fetch search results by facility");
	}
};
