import React from 'react'
// import Banner from '../components/banner'
import Banner from '../components/Home/Banner'
import Explore from '../components/Home/Explore'
import OurServices from '../components/Home/OurServices'
import Reviews from '../components/Home/Reviews'
import Youtube from '../components/Home/Youtube'
import SponsorsPartners from '../components/SponsorsPartners'

const Home = () => {
  return (
    <div>
      <Banner />
      <OurServices />
      <Explore />
      <SponsorsPartners />
      <Youtube />
      <Reviews />
    </div>
  )
}

export default Home
