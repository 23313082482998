import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Availability, Rating } from "../components/CompanionCard";
import { format, parseISO, differenceInYears } from "date-fns";
import ContactModal from "../components/ContactModal";
import { getEmbedUrl } from "../utils/embedUrl";
import CalendarAvailability from "../components/CalendarAvailability";
// import CalendarAvailability from "../components/CalendarAvailability";

const errorImage = require("../images/error-image.jpg");

const ExploreCompanion: React.FC = () => {
	const { companionId } = useParams<{ companionId: string }>();
	const [companionDetails, setCompanionDetails] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const fetchCompanionDetails = async () => {
			try {
				//	debugger;
				//const response = await axios.get(`${process.env.REACT_APP_API_URL}GetCompanion/${companionId}`);

				const currentDateTime = new Date().toISOString(); // ISO format datetime
				const response = await axios.get(`${process.env.REACT_APP_API_URL}GetCompanion/${companionId}`, {
					headers: {
						"X-Request-Timestamp": currentDateTime,
					},
				});

				setCompanionDetails(response.data);
			} catch (err: any) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchCompanionDetails();
	}, [companionId]);

	if (loading) {
		return (
			<div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center z-50">
				<div className="loader border-t-4 border-b-4 border-[#038aae] rounded-full w-12 h-12 animate-spin"></div>
			</div>
		);
	}

	if (error) {
		return <div>Error fetching companion details.</div>;
	}

	const formatReviewDate = (dateString: string) => {
		const date = parseISO(dateString);
		return format(date, "EEE do 'of' MMMM, yyyy");
	};

	const calculateAge = (dobString: string) => {
		const dob = parseISO(dobString);
		return differenceInYears(new Date(), dob);
	};

	const handleModalOpen = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const embedUrl = getEmbedUrl(companionDetails.videoUrl);

	return (
		<div className="bg-gray-100">
			{/* <div className="h-[60vh] flex items-center justify-center text-center">
        <h2 className="text-4xl">Thank you for trying, We are currently working on it!!!</h2>
      </div> */}
			<div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-[88px] max-w-7xl mx-auto px-4">
				<div className="col-span-1 sm:col-span-2 flex flex-col gap-8">
					<div className="w-full my-4 rounded-lg shadow-xl companions-container">
						{companionDetails.videoUrl ? (
							<iframe
								className="w-full md:w-[800px] px-4 md:mx-auto h-[500px]"
								width="420"
								height="615"
								src={embedUrl}
								title="Companion Video"
							></iframe>
						) : (
							<div className="flex items-center justify-center h-[500px] bg-gray-200 text-gray-700">
								<p className="text-[#038aae] text-2xl">Video not available</p>
							</div>
						)}
						<div className="grid grid-cols-1 sm:grid-cols-4 gap-3 px-8 py-4">
							<div className="sm:block flex flex-col items-center justify-center">
								<div className="">
									{companionDetails.profileImagePath.includes(
										`${process.env.REACT_APP_DEFAULT_URL}profileImage/`
									) ? (
										<img src={errorImage} alt="profile picture" className="rounded-full" />
									) : (
										<img
											src={companionDetails.profileImagePath}
											className="rounded-xl h-[180px]"
											alt="profile picture"
										/>
									)}
								</div>
								<div className="my-4 flex flex-col gap-1 ml-3">
									<h2 className="font-medium underline">Ratings</h2>
									<Rating rating={companionDetails.averageRating} />
								</div>
							</div>
							<div className="">
								<h1 className="text-xl font-bold">
									{companionDetails.firstName} {companionDetails.lastName}
								</h1>
								<div className="my-1">
									<h2 className="italic text-lg font-medium underline">Qualifications</h2>
									<div className="">
										{companionDetails.qualifications.map((item: any) => (
											<div key={item.educationalQualificationID}>
												{item.highestEducationalDegree}. {item.educationalQualificationName}
											</div>
										))}
									</div>
								</div>
								<div className="mt-5">
									<h2 className="italic text-lg font-medium underline">Seniors Served</h2>
									<div className="">{companionDetails.companionServiceCount} Seniors</div>
								</div>
							</div>
							<div className="mt-8">
								<h2 className="italic text-lg font-medium underline">Experience</h2>
								<ul className="list-disc">
									{companionDetails.companionExperience.map((item: any) => (
										<li className="text-gray-700 mb-2" key={item.experienceID}>
											{item.experience}
										</li>
									))}
								</ul>
							</div>
							<div className="mt-8">
								<h2 className="italic text-lg font-medium underline">Language</h2>
								<ul className="list-disc">
									{companionDetails.language.map((item: any) => (
										<li className="text-gray-700 mb-2" key={item.languageID}>
											{item.languageName}
										</li>
									))}
								</ul>
							</div>
						</div>
						<hr />
						<div className="mx-8 my-4">
							<h2 className="font-bold underline">Brief Introduction</h2>
							<h2>{companionDetails.introText}</h2>
						</div>
					</div>
					<div className="py-3 px-3">
						<h2 className="px-2 py-2 text-lg">Availability</h2>
						<hr />
						<div className="my-2 px-2">
							<CalendarAvailability availability={companionDetails.availability} />
							{/* <Availability availability={companionDetails.availability} /> */}
						</div>
					</div>
					<div className="w-full my-4 rounded-lg shadow-xl px-8">
						<div className="flex items-center justify-between py-3">
							<div className="flex flex-col gap-1">
								<h2 className="font-bold underline">Age</h2>
								<h2 className="capitalize text-lg">
									{calculateAge(companionDetails.dateOfBirth)} years old
								</h2>
							</div>
							<div className="flex flex-col gap-1">
								<h2 className="font-bold underline">Gender</h2>
								<h2 className="capitalize text-lg">{companionDetails.gender}</h2>
							</div>
						</div>
						<hr />
						<div className="my-4">
							<h2 className="underline font-bold py-2">Reviews</h2>
							<div className="flex flex-col">
								{companionDetails.reviews.map((review: any) => (
									<>
										<div>"{review.review}"</div>
										<div className="flex items-end justify-end text-sm font-medium italic">
											{formatReviewDate(review.dateAdded)} - {review.time}
										</div>
									</>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="col-span-1 flex flex-col gap-1 w-full ">
					{/* <div className="h-fit w-full bg-white flex-shrink-0 rounded-lg shadow-xl my-4">
            <div className="sticky w-full">
              <div className="py-3 px-3">
                <h2 className="px-2 py-2 text-lg">Availability</h2>
                <hr />
                <div className="my-2 px-2">
                  <CalendarAvailability availability={companionDetails.availability} />
                  <Availability availability={companionDetails.availability} />
                </div>
              </div>
            </div>
          </div> */}
					<div className="h-fit w-full bg-white flex-shrink-0 rounded-lg shadow-xl my-4">
						<div className="sticky w-full p-4">
							<div className="flex items-center justify-between py-3">
								<h2 className="italic">Status</h2>
								<h2 className="font-bold">
									{companionDetails.verificationStatus === true ? "Verified" : "Not Verified"}
								</h2>
							</div>
							<hr />
							<div className="flex items-center justify-between py-3">
								<h2 className="italic">Country</h2>
								<h2 className="font-bold">{companionDetails.country}</h2>
							</div>
							<hr />
							<div className="flex items-center justify-between py-3">
								<h2 className="italic">City</h2>
								<h2 className="font-bold">{companionDetails.city}</h2>
							</div>
							<hr />
							<div className="flex items-center justify-between py-3">
								<h2 className="italic">Hourly Rate</h2>
								<h2 className="font-bold text-[#038aae] text-xl">$29</h2>
							</div>
							<hr />
							<div className="flex items-center justify-center my-4">
								<button
									className="py-2 px-3 rounded-lg bg-gradient-to-br from-[#038aae] to-[#038aae90] text-white"
									onClick={handleModalOpen}
								>
									Contact us to schedule
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ContactModal
				email={companionDetails.emailAddress}
				phone={companionDetails.phoneNumber}
				isOpen={isModalOpen}
				onClose={handleModalClose}
				hourlyRate="$29"
			/>
		</div>
	);
};

export default ExploreCompanion;
