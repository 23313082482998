import React, { useEffect, useState } from "react";
import MultiSelection from "./MultiSelection";
import ErrorComponent from "./ErrorComponent";
import {
  addCompanionService,
  fetchServiceCategoryItems,
  handleApiError,
} from "../api/api";
import Loader from "./Loader";

interface Step1Props {
  onNext: () => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const [data, setData] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isSelectionValid, setIsSelectionValid] = useState(false);
  const [pageLoading, setPageloading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    validateSelections();
  }, [selectedOptions, data]);

  const fetchData = async () => {
    try {
      setPageloading(true)
      const data = await fetchServiceCategoryItems();
      setData(data);
      setPageloading(false)
    } catch (error) {
      setPageloading(false)
      handleApiError(error);
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    setError(error);
  };

  const handleSelectionChange = (label: string, selectedValue: string) => {
    const category = data.find((category) => category.name === label);
    const selectedItem = category?.items.find(
      (item: any) => item.name === selectedValue
    );
    if (selectedItem) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions.filter((option) => option.label !== label),
        { label, objectID: category.id, itemID: selectedItem.id },
      ]);
    }
  };

  const validateSelections = () => {
    const isValid = data
      .filter((category) => category.items.length > 0) // Only include categories with items
      .every((category) =>
        selectedOptions.some((option) => option.label === category.name)
      );
    setIsSelectionValid(isValid);
  };

  const handleNextClick = async () => {
    const companionID = sessionStorage.getItem("companionId");
    if (!companionID) {
      console.error("Companion ID is not available.");
      return;
    }
    const companionServiceString = selectedOptions
      .map(({ label, itemID, objectID }) => {
        const trimmedLabel = label.replace(/\s+/g, "");
        return `${trimmedLabel}$${objectID}:${itemID}/`;
      })
      .join("/");

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("Token is not available.");
      return;
    }
    const pageCount = 2;

    try {
      setLoading(true);
      await addCompanionService(
        companionID,
        companionServiceString,
        token,
        pageCount
      );
      setLoading(false);
      sessionStorage.setItem("page", `${pageCount}`);
      onNext();
    } catch (error) {
      handleApiError(error);
      handleError(error);
    }
  };

  if (pageLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="w-[500px] mx-auto px-3 sm:px-0">
      <h1 className="py-4 text-2xl text-center">
        What services Can you provide?
      </h1>
      {error && <ErrorComponent error={error} />}
      <div className="my-2 w-full">
        {data.map((category: any) => (
          <MultiSelection
            key={category.id}
            label={category.name}
            options={category.items.map((item: { id: any; name: any }) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(selectedValues) =>
              handleSelectionChange(category.name, selectedValues)
            }
          />
        ))}
      </div>
      <button
        onClick={handleNextClick}
        disabled={!isSelectionValid || loading}
        className={`rounded-lg w-24 text-white px-2 py-2 mb-4 ${
          !isSelectionValid || loading
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-gradient-to-br from-[#038aae] to-[#038aae90]"
        }`}
      >
        {loading ? "Loading..." : "Next"}
      </button>
    </div>
  );
};

export default Step1;
