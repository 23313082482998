import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid, faStarHalfStroke, faStar as faStarRegular } from "@fortawesome/free-solid-svg-icons";
import { getEmbedUrl } from "../utils/embedUrl";
import CalendarAvailability from "./CalendarAvailability";

const errorImage = require("../images/error-image.jpg");
const verifiedIcon = require("../images/verified2.png");

interface companionCardProps {
	searchResult: any;
}

const CompanionCard: React.FC<companionCardProps> = ({ searchResult }) => {
	const [currentTab, setCurrentTab] = useState("intro");
	const navigate = useNavigate();

	const handleTabChange = (tab: string) => setCurrentTab(tab);
	const handleExplore = () => navigate(`/explore-companion/${searchResult.companionID}`);

	return (
		<div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 cursor-pointer">
			<div className="flex flex-col items-center text-center">
				<div className="relative w-24 h-24 mb-4">
					<img
						src={
							searchResult.profileImagePath.includes(`${process.env.REACT_APP_DEFAULT_URL}profileImage/`)
								? errorImage
								: searchResult.profileImagePath
						}
						alt="profile"
						className="w-full h-full object-cover rounded-full"
					/>
					{searchResult.verificationStatus && (
						<img src={verifiedIcon} alt="Verified" className="absolute top-0 right-0 w-6 h-6" />
					)}
				</div>
				<h3 className="text-lg font-bold mb-2">
					{searchResult.firstName} {searchResult.lastName}
				</h3>
				<Rating rating={searchResult.averageRating} />
				<div className="mt-4">
					<h4 className="font-medium text-sm mb-1">Experience</h4>
					{searchResult.companionExperience.map((experience: any, index: any) => (
						<p key={index} className="text-sm">
							{experience.experience}
						</p>
					))}
				</div>
				<button
					onClick={handleExplore}
					className="mt-4 py-2 px-4 bg-gradient-to-br from-[#038aae] to-[#038aae90] text-white rounded-lg"
				>
					Explore
				</button>
			</div>
			<div className="mt-4">
				<div className="flex justify-between items-center mb-2">
					<button
						className={`tab-button ${currentTab === "intro" ? "active" : ""}`}
						onClick={() => handleTabChange("intro")}
					>
						Intro
					</button>
					{/* <button
						className={`tab-button ${currentTab === "video" ? "active" : ""}`}
						onClick={() => handleTabChange("video")}
					>
						Video
					</button> */}
					<button
						className={`tab-button ${currentTab === "activity" ? "active" : ""}`}
						onClick={() => handleTabChange("activity")}
					>
						Activities
					</button>
					<button
						className={`tab-button ${currentTab === "availability" ? "active" : ""}`}
						onClick={() => handleTabChange("availability")}
					>
						Availability
					</button>
				</div>
				<div className="tab-content">
					{currentTab === "intro" && <Intro introText={searchResult.introText} />}
					{/* {currentTab === "video" && <Video videoUrl={searchResult.videoUrl} />} */}
					{currentTab === "activity" && <Activity companionService={searchResult.companionServices} />}
					{currentTab === "availability" && <CalendarAvailability availability={searchResult.availability} />}
				</div>
			</div>
		</div>
	);
};

export const Rating: React.FC<{ rating: number }> = ({ rating }) => {
	const wholeStars = Math.floor(rating);
	const fractionalStar = rating - wholeStars;

	return (
		<div className="flex">
			{Array(wholeStars)
				.fill(0)
				.map((_, i) => (
					<FontAwesomeIcon key={i} icon={faStarSolid} className="text-yellow-500" />
				))}
			{fractionalStar > 0 && <FontAwesomeIcon icon={faStarHalfStroke} className="text-yellow-500" />}
			{Array(5 - wholeStars - (fractionalStar > 0 ? 1 : 0))
				.fill(0)
				.map((_, i) => (
					<FontAwesomeIcon key={i} icon={faStarRegular} className="text-yellow-500" />
				))}
		</div>
	);
};

const Intro: React.FC<{ introText: string }> = ({ introText }) => (
	<p className="text-sm text-gray-600">{introText || "Intro text is not available."}</p>
);

const Video: React.FC<{ videoUrl: string }> = ({ videoUrl }) => {
	const embedUrl = getEmbedUrl(videoUrl);
	return embedUrl ? (
		<div className="aspect-w-16 aspect-h-9">
			<iframe src={embedUrl} title="Companion Video" className="w-full h-full" allowFullScreen></iframe>
		</div>
	) : (
		<p className="text-sm text-gray-600">Video is not available.</p>
	);
};

interface CompanionServiceProps {
	companionService: {
		name: string;
		id: number;
		value: number;
		companionServiceNameLists: { name: string }[]; // Second-level mapping
	}[];
}

export const Activity: React.FC<CompanionServiceProps> = ({ companionService }) => {
	// console.log("companionService", companionService);
	const isCompanionService = companionService.length > 0;

	if (!isCompanionService) {
		return <div className="text-gray-600">No Companion activities.</div>;
	}

	return (
		<div>
			<ul className="pl-5">
				{companionService.map((service) => (
					<li key={service.id} className="text-sm text-gray-600">
						{service.name}
						{service.companionServiceNameLists?.length > 0 && (
							<ul className="list-disc pl-5">
								{service.companionServiceNameLists.map((subService, index) => (
									<li key={index} className="text-gray-500 mb-2">
										{subService.name}
									</li>
								))}
							</ul>
						)}
					</li>
				))}
			</ul>
		</div>
	);
};

interface AvailabilityProps {
	availability: Array<{
		availableDays: string[];
		availabilityStartTime: string;
		availabilityEndTime: string;
	}>;
}

export const Availability: React.FC<AvailabilityProps> = ({ availability }) => {
	const hasAvailability = availability.length > 0;
	const hasDays = availability.some((item) => item.availableDays && item.availableDays.length > 0);
	const hasTimes = availability.some((item) => item.availabilityStartTime && item.availabilityEndTime);

	if (!hasAvailability || !hasDays || !hasTimes) {
		return <div className="text-gray-600">No availability information provided.</div>;
	}

	return (
		<div>
			<div className="flex flex-col">
				<h2 className="mr-2 font-medium underline">Available Days</h2>
				<div className="flex gap-2 capitalize">
					{availability
						.map((item) => item.availableDays)
						.flat()
						.join(", ")}
				</div>
			</div>
			<div className="flex flex-col mt-4">
				<h2 className="mr-2 font-medium underline">Available Time</h2>
				<div className="flex flex-wrap gap-2">
					{availability.map((item, index) => (
						<div key={index} className="flex gap-2 bg-gray-100 p-1 rounded-lg">
							{item.availabilityStartTime} - {item.availabilityEndTime}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CompanionCard;
