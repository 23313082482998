import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan, faLessThan } from "@fortawesome/free-solid-svg-icons";
const quote1 = require("../../assets/icons/quote1.png");
const quote2 = require("../../assets/icons/quote2.png");

const reviewsData = [
  // {
  //   id: 1,
  //   name: "Dapo Paul",
  //   date: "12-04-2024",
  //   review:
  //     "LinkRx is really awesome. I've enjoyed the passion the companions put into taking care of my dad.",
  // },
  {
    id: 2,
    name: "John Doe",
    date: "05-03-2024",
    review:
      "I love that this program is giving my loved one more independence, which, in turn, is boosting their self-esteem. They are out in the community, accessing local resources, and building new connections. I'm happy to increase the hours when the weather improves and will leave it up to the staff at Langley Lodge to decide when additional days or hours are needed.",
  },
  {
    id: 3,
    name: "Jane Smith",
    date: "22-06-2024",
    review: "I appreciate the time and effort the companions are putting into the visits, little things like playing cards, the family forgot that that was an interest with all the 'other stuff' going on.",
  },
];

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-switch reviews every 5 seconds
  useEffect(() => {
    const autoSlide = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviewsData.length);
    }, 5000);

    return () => clearInterval(autoSlide); // Cleanup interval on component unmount
  }, []);

  // Handle manual switching
  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % reviewsData.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      currentIndex === 0 ? reviewsData.length - 1 : currentIndex - 1
    );
  };

  return (
    <div className="pt-16 pb-8 my-6">
      <div className="md:max-w-7xl mx-auto px-4">
        <h2 className="font-bold text-4xl text-center pb-16">Testimonials from Families</h2>
        <div className="flex items-center justify-center">
          <div className="flex gap-4 relative">
            <img src={quote1} alt="" className="h-4 w-4" />
            <div className="py-2">{reviewsData[currentIndex].review}</div>
            <img
              src={quote2}
              alt=""
              className="h-4 w-4 absolute right-[-5px] md:right-[-35px] bottom-0"
            />
          </div>
        </div>
        {/* <div className="flex items-center justify-center flex-col py-4">
          <h2 className="">{reviewsData[currentIndex].name}</h2>
          <p className="text-gray-400">{reviewsData[currentIndex].date}</p>
        </div> */}

        {/* Manual controls */}
        <div className="flex justify-center mt-4 space-x-4">
          <button
            className="px-4 py-2 bg-[#038aae] text-white rounded hover:bg-[#038aae90] transition-all"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faLessThan} className="text-white" />
          </button>
          <button
            className="px-4 py-2 bg-[#038aae] text-white rounded hover:bg-[#038aae90] transition-all"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faGreaterThan} className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
